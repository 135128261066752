import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import IconButton from '@mui/material/IconButton';
import OpenIcon from '@mui/icons-material/OpenInNewRounded';

type ActionsRendererProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>, item: unknown) => void;
  icon?: JSX.Element;
};

const ActionsRenderer: FC<CustomCellRendererProps & ActionsRendererProps> = ({
  onClick,
  data,
  icon = <OpenIcon />,
}) => {
  const onClickProxy = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClick(e, data);
    },
    [data, onClick]
  );
  return (
    <IconButton size="small" color="primary" onClick={onClickProxy}>
      {icon}
    </IconButton>
  );
};

export default ActionsRenderer;
